<template>
<b-overlay rounded="sm" :show="loading">
  <AuthWrapperAlternative>
    <template #card>
      <div class="d-md-flex justify-content-between">
        <div class="form-card shadow rounded-lg px-md-5 px-2 py-3">
          <b-card-text class="text-right mt-0 text-danger cursor-pointer" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Logout</span>
          </b-card-text>
          <div class="text-center">
            <div>
              <h1>Card Details</h1>
            </div>
            <div class="my-2">
              <p>Please upload a clear picture of your <span style="color: #144F6A">National ID ( Ghana Card ) </span>
                on
                a black background</p>
            </div>
          </div>
          <b-alert
              variant="danger"
              show
          >
            <div v-if="errorMessage" class="alert-body font-small-2">
              <b-card-text>
                <feather-icon icon="AlertCircleIcon" />
                <small>{{ errorMessage }}</small>
              </b-card-text>
            </div>
          </b-alert>
          <div>
            <validation-observer ref="idCardsForm">
              <b-form class="auth-forgot-password-form mt-2" @submit.prevent="saveCardDetails">
                <div class="d-flex align-items-center">
                  <div class="flex-fill">
                    <b-form-group label="Front Card" label-for="forgot-password-field">
                      <validation-provider #default="{ errors }" name="Email / Phone" rules="required">
                        <div>
                          <div class="preview-card">
                            <div>
                              <b-img :src="frontCardPath || savedUserIdFront" class="rounded-sm" fluid alt="front"></b-img>
                            </div>
                          </div>
                          <input id="forgot-password-field" v-model="frontCardPath" hidden type="text"
                                 placeholder="brucewayne@wayneenterprises.com"
                          >
                          <b-form-file v-show="false" ref="frontCardElement" accept="image/*" placeholder="" no-drop
                                       @input="onFrontImageRenderer"
                          />

                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="ml-2">
                    <b-button variant="primary" @click="$refs.frontCardElement.$el.childNodes[0].click()">
                      Browse
                    </b-button>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="flex-fill">
                    <b-form-group label="Back Card" label-for="back-card">
                      <validation-provider #default="{ errors }" name="Back Card" rules="required">
                        <div>
                          <div class="preview-card">
                            <div>
                              <b-img :src="backCardPath || savedUserIdBack" class="rounded-sm" fluid alt="back"></b-img>
                            </div>
                          </div>
                          <input id="back-image-path" v-model="backCardPath" hidden type="text"
                                 placeholder="brucewayne@wayneenterprises.com"
                          >
                          <b-form-file v-show="false" ref="backCardElement" accept="image/*" placeholder="" no-drop
                                       @input="onBackImageRenderer"
                          />

                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="ml-2">
                    <b-button variant="primary" @click="$refs.backCardElement.$el.childNodes[0].click()">
                      Browse
                    </b-button>
                  </div>
                </div>

                <b-button type="submit" variant="primary" block class="py-1 mt-2" :disabled="loading">
                  Next
                  <feather-icon icon="ChevronRightIcon" />
                </b-button>
              </b-form>
            </validation-observer>

            <div class="text-center mt-1">
              <Stepper />
            </div>

          </div>
        </div>

        <div class="info-card py-2">
          <div class="d-flex flex-column justify-content-between">
            <div class="mt-1 text-center">
              <h3 class="text-secondary">
Useful Tips
</h3>
            </div>
            <div class="px-3">
              <div class="pb-1">
                <p class="text-white m-0 p-0">
                  - Place card on a black surface
                </p>
                <p class="text-white m-0 p-0">
                  - Ensure the card covers a larger surface area in the picture with all corners of the card showing
                </p>
                <p class="text-white m-0 p-0">
                  - Ensure every card text are bold and legible in the picture
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <img src="@/assets/images/exxtra/ghcardfront.png" />
              </div>
            </div>

            <div class="px-3">
              <div><p class="text-white m-0 p-0 mt-1">
                - As described above submit an excellent and clear picture of the back of your id card
              </p></div>
              <div class="d-flex justify-content-center">
                <img class="" src="@/assets/images/exxtra/ghcardback.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AuthWrapperAlternative>
</b-overlay>
</template>

<script>
import store from '@/store/index'
import Stepper from "@/assets/svg/Stepper.svg";
import CardIcon from "@/assets/svg/CardIcon.svg";
import BottomDesign from '@/assets/svg/BottomDesign.svg'
import TurtleRentLogo from '@core/layouts/components/Logo.vue'
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import AuthWrapperAlternative from "@core/components/auth-wrapper/AuthWrapperAlternative.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  email,
  required
} from '@validations'
import {
  BAlert,
  BAvatar,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import {
  get
} from "lodash";
/* eslint-disable global-require */
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'

export default {
  components: {
    AuthWrapperAlternative,
    Rectangles,
    CardIcon,
    BottomDesign,
    Stepper,
    BOverlay,
    BAlert,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      hasErrorMessage: false,
      errorMessage: "",
      frontCardPath: "",
      backCardPath: "",
      frontCardImage: null,
      backCardImage: null,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    savedUserIdFront() {
      return this.getValueFromSource(this.currentUser, "national_ID_file.path")
    },
    savedUserIdBack() {
      return this.getValueFromSource(this.currentUser, "national_ID_file_back.path")
    },
    hasValidID() {
      return this.getValueFromSource(this.currentUser, "has_valid_id_card")
    },
    isValidForm() {
      return !!(this.frontCardImage || this.savedUserIdFront) && !!(this.backCardImage || this.savedUserIdBack)
    }
  },
  created() {
    const {
      error
    } = this.$route.query;
    if (error) {
      this.hasErrorMessage = true;
      this.errorMessage = 'The password reset token has expired. Please enter your email / phone to request for new token.'
      this.$router.replace({
        query: {}
      });
    }
  },
  methods: {
    async saveCardDetails() {
      if (this.isValidForm) {
        this.verifyIDCard()
      } else if (this.hasValidID) {
        this.$router.push({
          name: "onboarding-stage-2"
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: 'Sorry. You need to upload a new Front and Back ID Card to proceed'
          },
        });
      }
    },
    async verifyIDCard() {
      try {
        this.loading = true;
        this.errorMessage = '';

        const formData = new FormData();

        if (this.frontCardImage){
          formData.append("id_card", this.frontCardImage);
        }

        if (this.backCardImage){
          formData.append("id_card_back", this.backCardImage);
        }

        await this.useJwt().authService.uploadAndVerifyIdCard(formData);

        this.$router.push({
          name: "onboarding-stage-2"
        }).then(() => {}).catch(() => {});

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'You have successfully uploaded your ID Information'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onFrontImageRenderer(file) {
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.frontCardPath = reader.result
          this.frontCardImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    onBackImageRenderer(file) {
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.backCardPath = reader.result
          this.backCardImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.form-card {
  position: relative;
  width: 45%;
  height: 70%;
  border-radius: 10px;
  background-color: $white;
  .link {
    color: green;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    width: 95%;

  }

}
.info-card {
  position: relative;
  width: 45%;
  border-radius: 10px;
  background-color: $main-color;
  .link {
    color: green;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    width: 95%;
  }

}

</style>

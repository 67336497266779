<template>
  <section>
    <div class="auth-wrapper auth-v2">
      <div class="auth-inner">

        <!--heading slot-->
        <slot name="heading">

          <div class="brand-logo-wrapper">
            <b-link class="brand-logo">
              <router-link to="/">
                <turtle-rent-logo height="100px" :width="100" />
              </router-link>
            </b-link>
          </div>

        </slot>

        <div class="wrapper-alt">
          <div class="central-card-design">
            <Rectangles v-if="showRectangles" />

            <div class=" ">

              <!--  card slot -->
              <slot name="card">
              </slot>

            </div>
          </div>
        </div>

        <div class="bottom-design">
          <BottomDesign />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BOverlay, BLink } from "bootstrap-vue";
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import TurtleRentLogo from '@core/layouts/components/Logo.vue';
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";

export default {
  name: "AuthWrapperAlternative",
  components: {
    Rectangles,
    BOverlay,
    BottomDesign,
    TurtleRentLogo,
    BLink
  },
  props: {
    showRectangles: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.wrapper-alt {
  width: 70vw;
  position: absolute;
  top: 55%;
  z-index: 20 !important;
  left: 50%;
  transform: translate(-50%, -50%);


  @media screen and (max-width: 768px) {
    margin: auto !important;
    width: 100vw;
  }

}

</style>
